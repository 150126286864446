import { useEffect, useState } from 'react'
import {
  ShopOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { Button, Col, Pagination, Row, Table, Tag, Modal, Input, message, Tooltip } from 'antd'
import dayjs from 'dayjs'
import ProductDrawer from './components/Drawer'
import { Product } from 'stores/types/product.types'
import { useGlobalStore } from 'stores/global'
import { useProductStore } from 'stores/product'
import { deleteProduct, findAllProducts } from 'services/product.service'
import { useAuth } from 'stores/authorized'
import PermissionAccess from 'components/PermissionAccess'

const { confirm } = Modal
const { Column } = Table
const { Search } = Input

export interface ISearchState {
  searchText: string | undefined
}

const ProductPage = () => {
  const { scope } = useAuth()
  const { setPageHeader } = useGlobalStore()
  const { setProducts, setProductInfo, setProductItems } = useProductStore()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<Product>()
  const [productList, setProductList] = useState<Product[]>([])

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
    total: 0,
  })

  const [sorter] = useState({
    orderby: 'createdAt',
    sort: 'desc',
  })

  const [search, setSearch] = useState<ISearchState>({
    searchText: undefined,
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    findProducts()
  }, [])

  useEffect(() => {
    setPageHeader({
      title: 'Product Overview',
      subTitle: '',
      onBack: '#',
      backIcon: <ShopOutlined />,
      extra: [
        <PermissionAccess key={1} permission='product:create' scope={scope}>
          <Tooltip title='create new product'>
            <Button
              type='primary'
              shape='round'
              icon={<PlusOutlined className='text-base relative -top-0.5' />}
              onClick={() => {
                setIsOpen(true)
                setDataSource(undefined)
                setProducts([])
                setProductInfo([])
                setProductItems([])
              }}
            >
              Create product
            </Button>
          </Tooltip>
        </PermissionAccess>,
      ],
    })
  }, [scope?.[0]])

  const findProducts = async (param = {}) => {
    setLoading(true)
    const body = {
      ...pagination,
      ...sorter,
      ...param,
    }
    const payload: any = await findAllProducts(body)
    if (payload?.status?.code === 200) {
      setProductList(payload.data.results)
      setPagination({
        limit: body.limit,
        page: payload.data?.pagination?.page,
        total: payload.data?.pagination?.total,
      })
    }
    setLoading(false)
  }

  const changePage = (page: number, limit: any) => {
    const paginate = {
      ...pagination,
      page,
      limit,
    }
    setPagination(paginate)
    findProducts({ ...paginate, ...sorter })
  }

  const handelDelete = async (item) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Permanently delete this data',
      async onOk() {
        try {
          setLoading(true)
          const payload = await deleteProduct(item.productId)
          if (payload.status?.code === 200) {
            message.success('Deleted Successfully')
            findProducts()
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      },
    })
  }

  const onSearch = (value) => {
    const searching = {
      ...search,
      searchText: value,
    }
    setSearch(searching)
    findProducts({
      search: searching,
    })
  }

  const clearSearch = () => {
    const searching = {
      ...search,
      searchText: undefined,
      productType: undefined,
      categoryId: undefined,
    }
    setSearch(searching)
    findProducts({
      search: searching,
    })
  }

  const onClose = () => {
    setDataSource(undefined)
    setIsOpen(false)
    findProducts()
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <div className='flex items-center'>
            <Search
              allowClear
              size='large'
              placeholder='Search ...'
              onSearch={onSearch}
              onChange={(event) =>
                setSearch({
                  ...search,
                  searchText: event.target.value,
                })
              }
              value={search.searchText}
              style={{ width: 300 }}
              disabled={loading}
            />
            <Button type='link' onClick={clearSearch} disabled={!search.searchText}>
              clear
            </Button>
          </div>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            rowKey={(record) => `product_${record?.productId}`}
            dataSource={productList}
            className='mt-2 mb-2'
          >
            <Column
              title='Name'
              className='break-word'
              ellipsis
              render={(record) => (
                <div className='border-l-8 border-solid px-2' style={record?.color ? { borderColor: record.color } : { borderColor: 'transparent'}}>
                  <div className='text-md font-bold'>{record?.name}</div>
                  <div className='text-sm truncate text-gray-500 '>{record?.subDescription}</div>
                </div>
              )}
            />

            <Column
              title='Products'
              className='break-word'
              align='center'
              width={100}
              render={(record) => <div className='text-center'>{record?.items?.length ?? '-'}</div>}
            />
            <Column
              title='Created'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>
                  {dayjs(record?.createdAt).format('DD-MM-YYYY hh:mm:ss')}
                </div>
              )}
            />
            <Column
              title='Last updated'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>
                  {dayjs(record?.updatedAt).format('DD-MM-YYYY hh:mm:ss')}
                </div>
              )}
            />
            <Column
              title='status'
              align='center'
              className='break-word text-center'
              width={130}
              render={(record) => (
                <div className='text-center'>
                  {record?.isActive ? (
                    <Tag color='processing' className='m-0'>
                      Published
                    </Tag>
                  ) : (
                    <Tag color='default' className='m-0'>
                      Unpublished
                    </Tag>
                  )}
                </div>
              )}
            />
            <Column
              width={100}
              render={(record) => (
                <PermissionAccess permission='product:update' scope={scope}>
                  <div className='flex gap-2'>
                    <Tooltip title='Manage product'>
                      <Button
                        type='dashed'
                        icon={<FormOutlined />}
                        shape='circle'
                        onClick={() => {
                          setDataSource(record)
                          setIsOpen(true)
                        }}
                      />
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <Button
                        type='link'
                        icon={<DeleteOutlined />}
                        className='text-primary-orange'
                        onClick={() => {
                          handelDelete(record)
                        }}
                      />
                    </Tooltip>
                  </div>
                </PermissionAccess>
              )}
            />
          </Table>

          <Pagination
            showSizeChanger
            onChange={changePage}
            onShowSizeChange={changePage}
            pageSize={pagination.limit}
            current={pagination.page}
            total={pagination.total}
            className='text-right'
          />
        </Col>
      </Row>

      <ProductDrawer
        onClose={onClose}
        onSuccess={onClose}
        isOpen={isOpen}
        dataSource={dataSource}
      />
    </>
  )
}

export default ProductPage
