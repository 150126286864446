import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  Pagination,
  Row,
  Table,
  Tag,
  Modal,
  Input,
  message,
  Space,
  Tooltip,
  Select,
} from 'antd'
import {
  ExclamationCircleOutlined,
  CarryOutOutlined,
  FormOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { EventCalendar, EventTag } from 'stores/types/event.types'
import { deleteEvent, findEventTags, findEvents } from 'services/event.service'

import EventDrawer from './components/Drawer'
import EventDrawerTag from './components/DrawerTag'
import PermissionAccess from 'components/PermissionAccess'
import { useAuth } from 'stores/authorized'

const { confirm } = Modal
const { Column } = Table
const { Search } = Input
const { Option } = Select

export interface ISearchState {
  searchText: string | undefined
  tagId: string | undefined
}

const EventPage = () => {
  const { scope } = useAuth()
  const { setPageHeader } = useGlobalStore()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenTag, setIsOpenTag] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<EventCalendar | undefined>()
  const [eventCalendars, setEventCalendars] = useState<EventCalendar[]>([])
  const [eventTags, setEventTags] = useState<EventTag[]>([])

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
    total: 0,
  })

  const [sorter] = useState({
    orderby: 'createdAt',
    sort: 'desc',
  })

  const [search, setSearch] = useState<ISearchState>({
    searchText: undefined,
    tagId: undefined,
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    findAll()
    findAllTags()
  }, [])

  useEffect(() => {
    setPageHeader({
      title: 'Events Overview',
      subTitle: 'Content Management',
      backIcon: false,
      extra: [
        <PermissionAccess key={1} permission='medical_education:create' scope={scope}>
          <Button
            type='default'
            onClick={() => {
              setIsOpenTag(true)
            }}
          >
            Manage Tags
          </Button>
        </PermissionAccess>,
        <PermissionAccess key={2} permission='medical_education:create' scope={scope}>
          <Button
            type='primary'
            onClick={() => {
              setIsOpen(true)
              setDataSource(undefined)
            }}
          >
            Create New Event
          </Button>
        </PermissionAccess>,
      ],
    })
  }, [scope?.[0]])

  const findAll = async (param = {}) => {
    setLoading(true)
    const body = {
      ...pagination,
      ...sorter,
      ...param,
    }
    const payload: any = await findEvents(body)
    if (payload?.status?.code === 200) {
      setEventCalendars(payload.data?.results)
      setPagination({
        limit: body.limit,
        page: payload.data?.pagination?.page,
        total: payload.data?.pagination?.total,
      })
    }
    setLoading(false)
  }

  const findAllTags = async () => {
    setLoading(true)
    const payload: any = await findEventTags()
    if (payload?.status?.code === 200) {
      setEventTags(payload.data)
    }
    setLoading(false)
  }

  const changePage = (page: number, limit: any) => {
    const paginate = {
      ...pagination,
      page,
      limit,
    }
    setPagination(paginate)
    findAll({ ...paginate, ...sorter })
  }

  const handelDelete = async (item) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Permanently delete this data',
      async onOk() {
        try {
          setLoading(true)
          const payload = await deleteEvent(item.id)
          if (payload.status?.code === 200) {
            message.success('Deleted Successfully')
            findAll()
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      },
    })
  }

  const onSearch = (value) => {
    const searching = {
      ...search,
      searchText: value,
    }
    setSearch(searching)
    findAll({
      search: searching,
    })
  }

  const onSearchTag = (value) => {
    const searching = {
      ...search,
      tagId: value,
    }
    setSearch(searching)
    findAll({
      search: searching,
    })
  }

  const clearSearch = () => {
    const searching = {
      ...search,
      searchText: undefined,
      tagId: undefined,
    }
    setSearch(searching)
    findAll({
      search: searching,
    })
  }

  const onClose = () => {
    setDataSource(undefined)
    setIsOpen(false)
    findAll({
      search,
    })
  }

  const onCloseTag = () => {
    setIsOpenTag(false)
    findAllTags()
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Space className='flex' align='start'>
            <Search
              allowClear
              size='large'
              placeholder='Search..'
              onSearch={onSearch}
              onChange={(event) =>
                setSearch({
                  ...search,
                  searchText: event.target.value,
                })
              }
              value={search.searchText}
              style={{ width: 300 }}
              disabled={loading}
            />

            <Select
              size='large'
              placeholder='Choose eventTags'
              className='rounded-md'
              disabled={loading}
              allowClear
              onChange={onSearchTag}
              value={search.tagId}
              style={{ width: 200 }}
            >
              {eventTags.length &&
                eventTags.map((item: EventTag, index: number) => {
                  return (
                    <Option key={`search-tagId-${index}`} value={item.id}>
                      {item?.name?.en}
                    </Option>
                  )
                })}
            </Select>
            <Button
              type='link'
              onClick={clearSearch}
              disabled={!search.searchText && !search.tagId}
            >
              clear
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            rowKey={(record) => `event-${record?.id + ''}`}
            dataSource={eventCalendars}
            className='mt-2 mb-2'
          >
            <Column
              title='Event Name'
              className='break-word'
              ellipsis
              render={(record) => (
                <div>
                  <div className='text-md font-bold'>{record?.name?.en}</div>
                  <div className='text-xs text-gray-400'>
                    {
                      record?.products?.length ? (
                        <div className='flex gap-2'>
                          <div>Products: </div>
                          {record?.products?.sort((a, b) => a.productId - b.productId)?.map((item, key) => {
                            return (
                              <div key={`tag-product-key-${key}`} className='flex gap-2 items-center'>
                                <div className='w-2 h-2 rounded-full' style={{ backgroundColor: item.color }}></div>
                                <div>{item.name}</div>
                              </div>
                            )
                          })}
                        </div>
                      )
                        : <></>
                    }
                  </div>
                </div>
              )}
            />

            <Column
              title='Tags'
              className='break-word'
              render={(record) => {
                return (
                  !!(eventTags?.length && record?.tags?.length) &&
                  record?.tags?.map((item) => {
                    const value: any = eventTags.find((f) => f.id === item)
                    return (
                      <Tag key={`tag-event-${item}-${value.id}`} color={value?.options?.color}>
                        {value?.name?.en}
                      </Tag>
                    )
                  })
                )
              }}
            />
            <Column
              title='Limit Quota'
              className='break-word'
              align='center'
              width={80}
              render={(record) => (
                <div className='text-center tracking-wider'>
                  {record?.options?.quota}
                </div>
              )}
            />

            <Column
              title='Secure Code'
              className='break-word'
              align='center'
              width={100}
              render={(record) => (
                <div className='text-center tracking-wider'>
                  <Tooltip title={record?.options?.secureCode}>
                    ****
                  </Tooltip>
                </div>
              )}
            />
            <Column
              title='Event Date'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-xs'>
                  <div className='text-center'>{dayjs(record?.startDate).format('DD-MM-YYYY')}</div>
                  <div className='text-center'>
                    {record?.startTime}-{record?.endTime}
                  </div>
                </div>
              )}
            />

            <Column
              title='status'
              align='center'
              className='break-word text-center'
              width={130}
              render={(record) => (
                <div className='text-center'>
                  {record?.isActive ? (
                    <Tag color='processing' className='m-0'>
                      Published
                    </Tag>
                  ) : (
                    <Tag color='default' className='m-0'>
                      Unpublished
                    </Tag>
                  )}
                </div>
              )}
            />

            <Column
              width={150}
              render={(record) => (
                <div className='flex gap-2 justify-between'>
                  <Tooltip title='Users Registration Event'>
                    <Button
                      type='ghost'
                      icon={<CarryOutOutlined />}
                      shape='circle'
                      onClick={() => {
                        navigate(`/medical-education/event/${record?.id}/users`)
                      }}
                    />
                  </Tooltip>
                  <PermissionAccess permission='medical_education:update' scope={scope}>
                    <Tooltip title='Editable'>
                      <Button
                        type='dashed'
                        icon={<FormOutlined />}
                        shape='circle'
                        onClick={() => {
                          setDataSource(record)
                          setIsOpen(true)
                        }}
                      />
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <Button
                        type='link'
                        icon={<DeleteOutlined />}
                        shape='circle'
                        className='text-primary-orange'
                        onClick={() => {
                          handelDelete(record)
                        }}
                      />
                    </Tooltip>
                  </PermissionAccess>
                </div>
              )}
            />
          </Table>

          <Pagination
            showSizeChanger
            onChange={changePage}
            onShowSizeChange={changePage}
            pageSize={pagination.limit}
            current={pagination.page}
            total={pagination.total}
            className='text-right'
          />
        </Col>
      </Row>

      <EventDrawer
        onClose={onClose}
        onSuccess={onClose}
        isOpen={isOpen}
        dataSource={dataSource}
        tags={eventTags}
      />

      <EventDrawerTag onClose={onCloseTag} onSuccess={onCloseTag} isOpen={isOpenTag} />
    </>
  )
}

export default EventPage
