import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Pagination,
  Row,
  Table,
  Tag,
  Modal,
  Input,
  message,
  Space,
  Tooltip,
  Select,
} from 'antd'
import { ExclamationCircleOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { Article, ArticleCategory } from 'stores/types/article.types'
import { deleteArticle, findAllArticles, findArticleCategories } from 'services/article.service'
import { ArticleTypeEnum } from 'enums/common.enum'
import { useAuth } from 'stores/authorized'

import ArticleDrawer from './components/Drawer'
import FaqDrawerCategory from './components/DrawerCategory'
import PermissionAccess from 'components/PermissionAccess'

const { confirm } = Modal
const { Column } = Table
const { Search } = Input
const { Option } = Select
const ArticleType = ArticleTypeEnum.WEBINAR

export interface ISearchState {
  searchText: string | undefined
  categoryId: string | undefined
  articleType: string | undefined
}

const WebinarPage = () => {
  const { scope } = useAuth()
  const { setPageHeader } = useGlobalStore()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenCategory, setIsOpenCategory] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<Article | undefined>()
  const [articles, setArticles] = useState<Article[]>([])
  const [categories, setCategories] = useState<ArticleCategory[]>([])

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
    total: 0,
  })

  const [sorter] = useState({
    orderby: 'createdAt',
    sort: 'desc',
  })

  const [search, setSearch] = useState<ISearchState>({
    searchText: undefined,
    categoryId: undefined,
    articleType: ArticleType,
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    findArticles()
    findCategories()
  }, [])

  useEffect(() => {
    setPageHeader({
      title: 'Webinar Overview',
      subTitle: 'Content Management',
      backIcon: false,
      extra: [
        <PermissionAccess key={1} permission='medical_education:create' scope={scope}>
          <Button
            type='default'
            onClick={() => {
              setIsOpenCategory(true)
            }}
          >
            categories
          </Button>
        </PermissionAccess>,
        <PermissionAccess key={2} permission='medical_education:create' scope={scope}>
          <Button
            type='primary'
            onClick={() => {
              setIsOpen(true)
              setDataSource(undefined)
            }}
          >
            Create New Webinar
          </Button>
        </PermissionAccess>,
      ],
    })
  }, [scope?.[0]])

  const findArticles = async (param = {}) => {
    setLoading(true)
    const body = {
      ...pagination,
      ...sorter,
      search,
      ...param,
    }

    const payload: any = await findAllArticles(body)
    if (payload?.status?.code === 200) {
      setArticles(payload.data?.results)
      setPagination({
        limit: body.limit,
        page: payload.data?.pagination?.page,
        total: payload.data?.pagination?.total,
      })
    }

    setLoading(false)
  }

  const findCategories = async () => {
    setLoading(true)
    const query = {
      search: {
        categoryType: ArticleType,
      },
    }
    const payload: any = await findArticleCategories(query)
    if (payload?.status?.code === 200) {
      setCategories(payload.data)
    }
    setLoading(false)
  }

  const changePage = (page: number, limit: any) => {
    const paginate = {
      ...pagination,
      page,
      limit,
    }
    setPagination(paginate)
    findArticles({ ...paginate, ...sorter })
  }

  const handelDelete = async (item) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'Permanently delete this data',
      async onOk() {
        try {
          setLoading(true)
          const payload = await deleteArticle(item.id)
          if (payload.status?.code === 200) {
            message.success('Deleted Successfully')
            findArticles()
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      },
    })
  }

  const onSearch = (value) => {
    const searching = {
      ...search,
      searchText: value,
    }
    setSearch(searching)
    findArticles({
      search: searching,
    })
  }

  const onSearchCategory = (value) => {
    const searching = {
      ...search,
      categoryId: value,
    }
    setSearch(searching)
    findArticles({
      search: searching,
    })
  }

  const clearSearch = () => {
    const searching = {
      ...search,
      searchText: undefined,
      categoryId: undefined,
    }
    setSearch(searching)
    findArticles({
      search: searching,
    })
  }

  const onClose = () => {
    setDataSource(undefined)
    setIsOpen(false)
    findArticles({
      search,
    })
  }

  const onCloseCategory = () => {
    setIsOpenCategory(false)
    findCategories()
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Space className='flex' align='start'>
            <Search
              allowClear
              size='large'
              placeholder='Search..'
              onSearch={onSearch}
              onChange={(event) =>
                setSearch({
                  ...search,
                  searchText: event.target.value,
                })
              }
              value={search.searchText}
              style={{ width: 300 }}
              disabled={loading}
            />

            <Select
              size='large'
              placeholder='Choose categories'
              className='rounded-md'
              disabled={loading}
              allowClear
              onChange={onSearchCategory}
              value={search.categoryId}
              style={{ width: 200 }}
            >
              {categories.length &&
                categories.map((item: ArticleCategory, index: number) => {
                  return (
                    <Option key={`search-categoryId-${index}`} value={item.categoryId}>
                      {item?.name?.en}
                    </Option>
                  )
                })}
            </Select>
            <Button
              type='link'
              onClick={clearSearch}
              disabled={!search.searchText && !search.categoryId}
            >
              clear
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            rowKey={(record) => `article-${record?.id + ''}`}
            dataSource={articles}
            className='mt-2 mb-2'
          >
            <Column
              title='Title'
              className='break-word'
              ellipsis
              render={(record) => (
                <div>
                  <div className='text-md font-bold'>{record?.title}</div>
                  <div className='text-xs text-gray-400'>
                    {
                      record?.products?.length ? (
                        <div className='flex gap-2'>
                          <div>Products: </div>
                          {record?.products?.sort((a,b) => a.productId - b.productId)?.map((item, key) => {
                            return (
                              <div key={`tag-product-key-${key}`} className='flex gap-2 items-center'>
                                <div className='w-2 h-2 rounded-full' style={{ backgroundColor: item.color}}></div>
                                <div>{item.name}</div>
                              </div>
                            )
                          })}
                        </div>
                      )
                       : <></>
                    }
                  </div>
                </div>
              )}
            />
            <Column
              title='Category'
              className='break-word'
              width={150}
              render={(record) => (
                <div>
                  <div className='text-md font-bold'>{record?.category?.name?.en}</div>
                </div>
              )}
            />

            <Column
              title='Created'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>{dayjs(record?.createdAt).format('DD-MM-YYYY')}</div>
              )}
            />
            <Column
              title='Updated'
              className='break-word'
              align='center'
              width={130}
              render={(record) => (
                <div className='text-center'>{dayjs(record?.updatedAt).format('DD-MM-YYYY')}</div>
              )}
            />
            <Column
              title='Status'
              align='center'
              className='break-word text-center'
              width={130}
              render={(record) => (
                <div className='text-center'>
                  {record?.isActive ? (
                    <Tag color='processing' className='m-0'>
                      publish
                    </Tag>
                  ) : (
                    <Tag color='default' className='m-0'>
                      unpublish
                    </Tag>
                  )}
                </div>
              )}
            />
            <Column
              width={100}
              render={(record) => (
                <PermissionAccess permission='medical_education:update' scope={scope}>
                  <div className='flex gap-2'>
                    <Tooltip title='Editable'>
                      <Button
                        type='dashed'
                        icon={<FormOutlined />}
                        shape='circle'
                        onClick={() => {
                          setDataSource(record)
                          setIsOpen(true)
                        }}
                      />
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <Button
                        type='link'
                        icon={<DeleteOutlined />}
                        shape='circle'
                        className='text-primary-orange'
                        onClick={() => {
                          handelDelete(record)
                        }}
                      />
                    </Tooltip>
                  </div>
                </PermissionAccess>
              )}
            />
          </Table>

          <Pagination
            showSizeChanger
            onChange={changePage}
            onShowSizeChange={changePage}
            pageSize={pagination.limit}
            current={pagination.page}
            total={pagination.total}
            className='text-right'
          />
        </Col>
      </Row>

      <ArticleDrawer
        onClose={onClose}
        onSuccess={onClose}
        isOpen={isOpen}
        dataSource={dataSource}
        categories={categories}
        articleType={ArticleType}
      />

      <FaqDrawerCategory
        onClose={onCloseCategory}
        onSuccess={onCloseCategory}
        isOpen={isOpenCategory}
        categoryType={ArticleType}
      />
    </>
  )
}

export default WebinarPage
