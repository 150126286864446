import { Button, Col, Divider, Form, FormInstance, Input, message, Modal, PageHeader, Row, Select, Spin, Switch } from 'antd'
import { PlusOutlined, LoadingOutlined, PictureOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useGlobalStore } from 'stores/global'
import { TimePicker } from 'components/features/picker'
import { EventSectionTypeEnum } from 'enums/event.enum'

import dayjs from 'dayjs'
import FileUpload from 'components/features/upload/Upload'
import { createEventAgenda, findEventAgenda, updateEventAgenda } from 'services/event.service'

const { confirm } = Modal
const { Option } = Select
const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
interface IFormContentProps {
  form: FormInstance
  eventId: number | string | undefined
  dataSource: any
  onSuccess: (value: any) => void
}

const FormAgenda = ({ form, eventId, dataSource, onSuccess }: IFormContentProps) => {
  const { languages } = useGlobalStore()
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    setLoading(false)

    if (dataSource?.id) {
      const currentDate = dayjs().format('YYYY-MM-DD')
      form.setFieldsValue({
        ...dataSource,
        startTime: dayjs(`${currentDate} ${dataSource.startTime}:00`),
        endTime: dayjs(`${currentDate} ${dataSource.endTime}:00`)
      })
      if (dataSource?.speakerPhoto || dataSource?.imageUrl) {
        setImageUrl(dataSource?.speakerPhoto || dataSource?.imageUrl)
      }
    } else {
      const defaultAgendar = {
        id: generateUUID(),
        topic: null,
        speaker: {
          name: null,
          label: null,
          caption: null
        },
        time: {
          start: dayjs().set('hour', 9).startOf('hour'),
          end: dayjs().set('hour', 16).startOf('hour')
        }
      }
      form.setFieldsValue(defaultAgendar)
    }
  }, [])

  const onFinish = (values: any) => {
    console.log(values)
    if (eventId) {
      confirm({
        title: 'Do you want to save it?',
        icon: <ExclamationCircleOutlined />,
        content: 'Please check infomation before Submiting!',
        onOk() {
          const itemValue = {
            ...values,
            startTime: dayjs(values.startTime).format('HH:mm'),
            endTime: dayjs(values.endTime).format('HH:mm'),
            eventId
          }
          const formData = new FormData()
          if (fileUpload) {
            formData.append('file', fileUpload, `speakerPhoto-ITOON-${fileUpload.name}-[${values?.id}]`)
            formData.append('speakerPhoto', 'null')
          }
          for (const item of Object.keys(itemValue)) {
            const value = ['topic', 'speaker'].includes(item)
              ? JSON.stringify(itemValue[item])
              : itemValue[item]
            formData.append(item, value)
          }
          const promise = dataSource?.id
            ? updateEventAgenda(dataSource.id, formData)
            : createEventAgenda(formData)
          promise
            .then((response) => {
              if ([200, 201].includes(response.status.code)) {
                message.success('Successfully saved.')
                if (typeof onSuccess === 'function') {
                  form.resetFields()
                  onSuccess(response.data)
                }
              }
              setLoading(false)
            })
            .catch((error) => {
              setLoading(false)
              console.error(error)
            })
        }
      })
    } else {
      const itemValues = {
        ...values,
        startTime: dayjs(values.startTime).format('HH:mm'),
        endTime: dayjs(values.endTime).format('HH:mm'),
        file: fileUpload,
        imageUrl
      }
      onSuccess(itemValues)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  const [imageUrl, setImageUrl] = useState<string>()
  const [fileUpload, setFileUpload] = useState<File>()
  const onUpload = (upload) => {
    setImageUrl(upload.imageUrl)
    setFileUpload(upload.file)
  }

  const onDelete = (file) => {
    console.log('onDelete', file)
    let pathUrl = file
    if (typeof file !== 'string' && file?.path.includes('http')) {
      pathUrl = file?.path
    }
    if (typeof pathUrl === 'string' && pathUrl?.includes('http')) {
      confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleOutlined />,
        content: 'Permanently delete this image',
        onOk() {
          setImageUrl(undefined)

          // updateEvent(eventId, {
          //   coverImage: null,
          // })
          //   .then((response) => {
          //     if (response.status.code === 200) {
          //       message.success('Deleted Successfully.')
          //       setImageUrl(undefined)
          //     }
          //   })
          //   .catch((error) => {
          //     message.error('Something went wrong! please try again later.')
          //     console.error(error)
          //   })
        },
      })
    } else {
      setImageUrl(undefined)
      setFileUpload(undefined)
    }
  }

  const [speakerDisabled, setSpeakerDisabled] = useState(false)
  const onChangeSection = (value) => {
    if (value === EventSectionTypeEnum.LUNCH) {
      setSpeakerDisabled(true)
      languages.map((lang) => {
        form.setFieldValue(['topic', lang.locale], 'Let\'s take a lunch break')
        form.setFieldValue(['speaker', lang.locale, 'name'], '')
        form.setFieldValue(['speaker', lang.locale, 'label'], '')
        form.setFieldValue(['speaker', lang.locale, 'caption'], '')
      })
      return
    }
    if (value === EventSectionTypeEnum.BREAK) {
      setSpeakerDisabled(true)
      languages.map((lang) => {
        form.setFieldValue(['topic', lang.locale], 'Let\'s take a break')
        form.setFieldValue(['speaker', lang.locale, 'name'], '')
        form.setFieldValue(['speaker', lang.locale, 'label'], '')
        form.setFieldValue(['speaker', lang.locale, 'caption'], '')
      })
      return
    }

    setSpeakerDisabled(false)
  }

  return (
    <>
      <div className='form-agenda'>
        <div className=''>
          <PageHeader
            title='Agenda Management'
            subTitle='Manage Content by Language'
            className='pt-0 px-0 mb-5 border-b border-solid'
          />
          <Form
            form={form}
            name='article-form'
            layout='vertical'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            initialValues={{
              isActive: true,
            }}
            requiredMark={false}
            scrollToFirstError
            className='p-5'
          >
            <Spin
              tip='Loading...'
              spinning={loading}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            >
              <Form.Item name='id' className='hidden' noStyle>
                <Input hidden />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Select a section type"
                    name={'sectionType'}
                    rules={[{ required: true, message: 'Please select a section type' }]}
                  >
                    <Select
                      placeholder="Select a section type"
                      size='large'
                      onChange={onChangeSection}
                    >
                      <Option value={EventSectionTypeEnum.LECTURE}>
                        Section Lecture
                      </Option>
                      <Option value={EventSectionTypeEnum.WORKSHOP}>
                        Section Lecture & Hand on workshop
                      </Option>
                      <Option value={EventSectionTypeEnum.LUNCH}>
                        Lunch break
                      </Option>
                      <Option value={EventSectionTypeEnum.BREAK}>
                        Take a break
                      </Option>
                    </Select>
                  </Form.Item>

                  <div className='flex gap-2 justify-between'>
                    <Form.Item
                      label='Start time section'
                      name='startTime'
                      rules={[{ required: true, message: 'Please enter speaker start time' }]}
                      className='w-1/2'
                    >
                      <TimePicker
                        size='large'
                        format={'HH:mm'}
                        placeholder='Start'
                        allowClear={false}
                        className='w-full'
                      />
                    </Form.Item>

                    <Form.Item
                      label='End at time section'
                      name='endTime'
                      rules={[{ required: true, message: 'Please enter speaker end at time' }]}
                      className='w-1/2'
                    >
                      <TimePicker
                        size='large'
                        format={'HH:mm'}
                        placeholder='End'
                        allowClear={false}
                        className='w-full'
                      />
                    </Form.Item>
                  </div>
                </Col>

                <Col span={12}>
                  <div className='upload-full-w group'>
                    <FileUpload
                      onUpload={onUpload}
                      onDelete={(file) => onDelete(file)}
                      imageUrl={imageUrl}
                      className=''
                    >
                      <div className='w-full flex gap-4 justify-center align-middle items-center  min-h-[120px] text-center p-5 bg-gray-100 text-gray-500 border border-solid rounded-md group-hover:border-primary-100'>
                        <PictureOutlined className='text-7xl max-w-xs group-hover:scale-110 transition duration-300 ease-in-out' />
                        <div className='text-sm mt-1.5 group-hover:scale-110 transition duration-300 ease-in-out'>
                          Browse or Drop
                          <div className='font-bold underline'>Speaker Photo</div> here
                        </div>
                      </div>
                    </FileUpload>
                    <div className='mt-1 text-xs text-gray-400 tracking-wider'>
                      <div className=''>
                        Maximum file size: 2 MB. Allowed file types: JPG, PNG, WEBP
                      </div>
                      <div className=''>
                        Image size 150x150
                      </div>
                    </div>
                  </div>
                </Col>

                <Divider />
                {
                  languages?.map((lang, index) => {
                    return (
                      <Col span={12} key={`topic-${index}`}>
                        <Row gutter={16}>
                          <Col span={24}>
                            <Form.Item
                              label={`${lang.country} Agenda`}
                              name={['topic', lang.locale]}
                              rules={[{ required: true, message: 'Please enter topic of agenda' }]}
                            >
                              <Input placeholder='Topic' size='large' />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name={['speaker', lang.locale, 'name']}
                              rules={[{ required: !speakerDisabled, message: 'Please enter Speaker name' }]}
                            >
                              <Input placeholder='Speaker Name' size='large' disabled={speakerDisabled}/>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name={['speaker', lang.locale, 'label']}
                              rules={[{ required: false, message: 'Please enter Speaker caption' }]}
                            >
                              <Input placeholder='Speaker caption (Not Required)' size='large' disabled={speakerDisabled}/>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name={['speaker', lang.locale, 'caption']}
                              rules={[{ required: false, message: 'Please enter Speaker caption' }]}
                            >
                              <Input placeholder='Speaker caption (Not Required)' size='large' disabled={speakerDisabled}/>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    )
                  })
                }
              </Row>

              <Row gutter={16} className='mt-5 pt-5 border-t border-solid'>
                {/* <Col span={24} className='mt-5 pt-5 border-t border-solid'>
                  <div className='flex items-center'>
                    <Form.Item
                      name={['options', 'allSpeaker']}
                      valuePropName='checked'
                      className='!mb-0'>
                      <Switch defaultChecked />
                    </Form.Item>
                    <div className='ml-3 text-xs text-gray-400'>
                      Display All speakers
                    </div>
                  </div>
                </Col> */}
              </Row>
            </Spin>
          </Form>
        </div>
      </div>
    </>
  )
}

export default FormAgenda
