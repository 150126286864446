import { useEffect, useState } from 'react'
import {
  Col,
  Row,
  Spin,
  Form,
  Input,
  Modal,
  Switch,
  message,
  PageHeader,
  FormInstance,
  Button,
} from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined, FormOutlined } from '@ant-design/icons'
import { groupBy } from 'utils/common'
import { useGlobalStore } from 'stores/global'
import { useProductStore } from 'stores/product'
import { LanguageEnum } from 'enums/language.enum'
import { createProduct, deleteProductItem, updateProduct } from 'services/product.service'

import dayjs from 'dayjs'
import ProductInfoDrawer from './DrawerProductInfo'
import ProductItemDrawer from './DrawerProductItem'
import TableProductItem from './TableProductItem'

const { confirm } = Modal
const colorPlates = ['#0c62c9', '#F1700E', '#f5a623', '#0e67d1', '#0676fa', '#9320fa', '#2debc0']
interface IProps {
  form: FormInstance
  productId?: number
  onSuccess?: (item?: any) => void
  dataSource?: any
}

const FormProduct = ({ form, productId, onSuccess, dataSource = {} }: IProps) => {
  const { languages } = useGlobalStore()
  const { productItems, setProductItems, productInfo, setProductInfo } = useProductStore()

  const [loading, setLoading] = useState<boolean>(true)
  const [color, setColor] = useState<string>()

  useEffect(() => {
    setLoading(false)
    if (productId) {
      const groupInfo = groupBy(dataSource?.info, ['locale'])
      for (const item of languages) {
        if (groupInfo[item.locale]) {
          groupInfo[item.locale].sort((a, b) => a.order - b.order)
        }
      }
      setDataInformations(groupInfo)
    } else {
      let dataInfo = {}
      languages.forEach((item) => {
        dataInfo = {
          ...dataInfo,
          [item.locale]: [],
        }
      })
      setDataInformations(dataInfo)
    }
  }, [])

  const onFinish = (values: any) => {
    console.log(values)
    console.log('item', productItems)
    console.log('info', dataInformation, productInfo)
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        let itemValue = {
          ...values,
          sku: values.name,
        }
        if (!productId) {
          itemValue = {
            ...itemValue,
            info: dataInformation,
            items: productItems,
          }
        }
        const formData = new FormData()
        for (const item of Object.keys(itemValue)) {
          if (item === 'info') {
            for (const lang of languages) {
              itemValue.info[lang.locale].map((info, index) => {
                if (info?.fileUpload) {
                  const file = info.fileUpload
                  formData.append(
                    'files[]',
                    file,
                    `PRODUCT_INFO-${lang.locale}-${index}-ITOON-${file.name}`,
                  )
                  delete info.fileUpload
                  delete info.imageUrl
                }
                formData.append(
                  'info[]',
                  JSON.stringify({ ...info, name: info.subTitle, order: index }),
                )
              })
            }
          }

          if (item === 'items') {
            itemValue.items.forEach((item, index) => {
              if (item?.filesUpload?.length) {
                for (const file of item.filesUpload) {
                  formData.append('files[]', file, `PRODUCT_ITEM-${index}-ITOON-${file.name}`)
                }
                delete item.filesUpload
              }
              formData.append('items[]', JSON.stringify({ ...item }))
            })
          }

          if (!['info', 'items'].includes(item)) {
            formData.append(item, itemValue[item])
          }
        }

        console.log('itemValue', itemValue)
        const promise = productId ? updateProduct(productId, formData) : createProduct(formData)
        promise
          .then((response) => {
            setLoading(false)
            if ([200, 201].includes(response.status.code)) {
              message.success('Successfully saved.')
              if (typeof onSuccess === 'function') {
                onSuccess(response.data)
              }
            }
          })
          .catch((error) => {
            setLoading(false)
            console.error(error)
          })
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  const onRemoveItem = (item, index) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: `Permanently delete this product ${item?.name}`,
      onOk() {
        if (item?.productItemId) {
          deleteProductItem(item.productItemId)
            .then((response) => {
              if (response.status.code === 200) {
                message.success('Deleted Successfully.')
              }
            })
            .catch((error) => {
              message.error('Something went wrong! please try again later.')
              console.error(error)
            })
        }
        setProductItems(productItems.filter((_, i) => i !== index))
      },
    })
  }

  const [isOpenInfo, setIsOpenInfo] = useState<boolean>(false)
  const [dataInformation, setDataInformations] = useState<any>()
  const [langActive, setLangActive] = useState<string>(LanguageEnum.TH)
  const onSuccessProductInfo = (info) => {
    setDataInformations({
      ...dataInformation,
      [langActive]: info,
    })
    setIsOpenInfo(false)
  }

  const [isOpenProduct, setIsOpenProduct] = useState<boolean>(false)
  const [dataProductItem, setDataProductItem] = useState<any>()
  const [indexProduct, setIndexProduct] = useState<number>()
  const onSuccessProductItem = () => {
    setIsOpenProduct(false)
  }

  return (
    <>
      <Form
        form={form}
        name='product-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          info: [],
          isActive: true,
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={16}>
            <Form.Item name='productId'>
              <Input hidden />
            </Form.Item>

            <Col span={12}>
              <Form.Item
                label='Brand Name'
                name='name'
                rules={[{ required: true, message: 'please enter product brand name' }]}
              >
                <Input size='large' placeholder='example: e.p.t.q' />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label=' '
              >
                <div className='flex gap-2 items-center'>
                  {colorPlates?.map((value, key) => {
                    return (
                      <div
                        key={`color-${key}`}
                        className={`${color === value ? 'scale-125' : ''
                          } h-10 w-10 cursor-pointer transition duration-300 ease-in-out hover:scale-110`}
                        style={{ backgroundColor: value }}
                        onClick={() => {
                          setColor(value)
                          form.setFieldValue('color', value)
                        }}
                      ></div>
                    )
                  })}
                </div>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                shouldUpdate
                name='color'
                rules={[{ required: true, message: 'Color code required' }]}
                label='Color Code'
              >
                <Input size='large' placeholder='e.g. #909090' />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label='A short product descriptions'
                name='subDescription'
                rules={[{ required: true, message: 'please input short description' }]}
              >
                <Input.TextArea size='large' placeholder='tell me about a product.' rows={3} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <div className='p-2 border border-solid rounded-md'>
                <PageHeader
                  title='Content'
                  subTitle='Information management'
                  className='p-0 mb-2'
                />
                {languages.map((item) => {
                  return (
                    <div key={item.code} className='flex gap-8 mb-2 px-2 items-center'>
                      <div className='w-40'>
                        <span className='text-gray-500'>Language -</span> {item.country}
                      </div>
                      <div className='w-[280px]'>
                        <span className='text-gray-500'>Last updated -</span>{' '}
                        {dataInformation?.[item.locale]?.[0]?.updatedAt
                          ? dayjs(dataInformation[item.locale][0].updatedAt).format(
                            'D MMM YYYY hh:mm A',
                          )
                          : 'No content available'}
                      </div>
                      <div className='w-[100px]'>
                        <span className='text-gray-500'>Layouts -</span>{' '}
                        {dataInformation?.[item.locale]?.length}
                      </div>
                      <div>
                        <Button
                          type='ghost'
                          size='small'
                          block
                          icon={<FormOutlined className='relative -top-0.5' />}
                          onClick={() => {
                            setLangActive(item.locale)
                            setProductInfo(dataInformation[item.locale])
                            setIsOpenInfo(true)
                          }}
                        >
                          Click to manage content [{item.locale.toUpperCase()}]
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24} className='mt-5'>
              <div className='p-2 border border-solid rounded-md'>
                <PageHeader
                  title='Products'
                  subTitle="Product's item management"
                  className='p-0'
                  extra={[
                    <Button
                      key='add-1'
                      type='ghost'
                      shape='round'
                      className='border-primary text-primary hover:bg-primary hover:text-white hover:border-white'
                      onClick={() => {
                        setDataProductItem(undefined)
                        setIndexProduct(undefined)
                        setIsOpenProduct(true)
                      }}
                    >
                      Add Product SKU
                    </Button>,
                  ]}
                />
                <div>
                  <TableProductItem
                    onClick={(item, index) => {
                      console.log(item, index)
                      setDataProductItem(item)
                      setIndexProduct(index)
                      setIsOpenProduct(true)
                    }}
                    onDelete={(item, index) => onRemoveItem(item, index)}
                    productItems={productItems}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24} className='mt-5'>
              <div className='flex items-center'>
                <Form.Item name='isActive' valuePropName='checked' className='!mb-0'>
                  <Switch defaultChecked />
                </Form.Item>
                <div className='ml-3 text-xs text-gray-400'>
                  This setting determines which data will be listed on status (Active/InActive)
                </div>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>

      <ProductInfoDrawer
        isOpen={isOpenInfo}
        lang={langActive}
        productId={productId}
        onClose={() => setIsOpenInfo(false)}
        onSuccess={onSuccessProductInfo}
      />

      <ProductItemDrawer
        isOpen={isOpenProduct}
        indexItem={indexProduct}
        dataSource={dataProductItem}
        productId={productId}
        onClose={() => setIsOpenProduct(false)}
        onSuccess={onSuccessProductItem}
      />
    </>
  )
}

export default FormProduct
