export enum EventSectionTypeEnum {
  LECTURE = 'LECTURE',
  WORKSHOP = 'WORKSHOP',
  BREAK = 'BREAK',
  LUNCH = 'LUNCH'
}

export enum EventStatusTypeEnum {
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  APPROVED = 'APPROVED',
  JOINED = 'JOINED',
  REJECTED = 'REJECTED'
}