import { Button, Form, FormInstance, Input } from 'antd'
import { PlusOutlined, FormOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import ClinicBranchDrawer from './DrawerBranch'
import { useClinicStore } from 'stores/clinic'
import { LanguageEnum } from 'enums/language.enum'

interface IFormBranchesProps {
  form: FormInstance
  lang?: string
  clinicId?: number
}

const FormBranches = ({ form, lang = LanguageEnum.TH, clinicId }: IFormBranchesProps) => {
  const { branches, setBranches } = useClinicStore()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [branchIndex, setBranchIndex] = useState<number>(0)
  const [dataSource, setDataSource] = useState<any>({})

  useEffect(() => {
    console.log(branches)
  }, [])

  const onClose = () => {
    setIsOpen(false)
    if (!clinicId && !branches[branchIndex]) {
      document.getElementById(`remove-${branchIndex}`)?.click()
    }
  }

  const editBranches = (indexItem) => {
    console.log('branches[indexItem]::', branches[indexItem])
    setIsOpen(true)
    setBranchIndex(indexItem)
    setDataSource(branches[indexItem])
  }

  const callbackSuccess = (item) => {
    setIsOpen(false)
    const branchValues = form.getFieldValue('branches')
    if (branchValues?.length) {
      branchValues[branchIndex] = {
        name: item.branchName[lang],
      }

      const branchItems = branches
      branchItems[branchIndex] = item
      setBranches(branchItems)
    }
  }

  return (
    <>
      <div className='form-social'>
        <Form.List name='branches'>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <div key={key} className='flex gap-4 justify-between'>
                  <div className='flex gap-4 w-4/5'>
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[{
                        required: true,
                        whitespace: true,
                        message: 'please input name of branches',
                      }]}
                      className='w-1/2 mx-1 mb-0'
                    >
                      <Input
                        placeholder='Branches'
                        disabled={true}
                        className='!bg-white !text-gray-600 !border-gray-100 text-base'
                      />
                    </Form.Item>

                    <div className='text-xs text-gray-500 mt-1'>
                      {
                        branches?.[name]?.products?.length ? (
                          <div className='flex gap-2 items-center min-h-[30px]'>
                            <div >Products: </div>
                            {branches?.[name]?.products?.sort((a: any, b: any) => a.productId - b.productId)?.map((item, key) => {
                              return (
                                <div key={`tag-product-key-${key}`} className='flex gap-2 items-center'>
                                  <div className='w-2 h-2 rounded-full' style={{ backgroundColor: item.color }}></div>
                                  <div>{item.name}</div>
                                </div>
                              )
                            })}
                          </div>
                        )
                          : <></>
                      }
                    </div>
                  </div>
                  <div>
                    <Button
                      type='ghost'
                      shape='circle'
                      icon={<FormOutlined />}
                      onClick={() => {
                        editBranches(index)
                      }}
                    ></Button>
                    <Button
                      id={`remove-${index}`}
                      type='link'
                      className='border-none hover:border-solid'
                      onClick={() => remove(name)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ))}

              <Form.Item className='text-right'>
                <Button
                  type='dashed'
                  size='small'
                  className='w-[150px] hover:bg-primary hover:text-white'
                  onClick={() => {
                    add()
                    setIsOpen(true)
                    setBranchIndex(fields.length)
                    setDataSource({})
                  }}
                  icon={<PlusOutlined className='text-base relative -top-0.5' />}
                >
                  Add Branches
                </Button>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>

        {!!isOpen && (
          <ClinicBranchDrawer
            isBranches={true}
            isOpen={isOpen}
            dataSource={dataSource}
            clinicId={clinicId}
            onClose={onClose}
            onSuccess={callbackSuccess}
          />
        )}
      </div>
    </>
  )
}

export default FormBranches
