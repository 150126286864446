import { MenuPermissionEnum } from 'enums/roles.enum'
import { Auth } from 'stores/types/authorized.types'

const STORAGE_KEY = 'backend.aestemaworld'

export const STORAGE_AUTH = `${STORAGE_KEY}.app.auth`
export const STORAGE_INFO = `${STORAGE_KEY}.app.info`
export const AUTHEN = {
  ACCESS_TOKEN: `${STORAGE_KEY}.access.token`,
  AUTHEN_TOKEN: `${STORAGE_KEY}.authoriz.token`,
}

export const Cookie = {
  WEB_CONFIG: `${STORAGE_KEY}.web.config`,
}

export enum LOGIN_TYPE {
  CONTINUE = 'CONTINUE',
  OK = 'OK',
  FAILED = 'FAILED',
}

export const isAuthen = () => {
  const info = localStorage.getItem(STORAGE_INFO)
  if (typeof info === 'string' && info) {
    return JSON.parse(info) as Auth
  }
  return {} as any
}

export const isLoggedIn = (): boolean => {
  return !!(Object.keys(isAuthen())?.length && localStorage.getItem(STORAGE_AUTH))
}

export const RolePermission = Object.keys(MenuPermissionEnum).map((item) => {
  return {
    name: MenuPermissionEnum[item],
    value: MenuPermissionEnum[item].replace(/ /g, '_').toLowerCase(),
  }
})
