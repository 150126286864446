import { useEffect, useState } from 'react'
import { Drawer, Form, Button } from 'antd'
import { ArticleCategory } from 'stores/types/article.types'

import FormWebinar from './FormWebinar'
import { ArticleTypeEnum } from 'enums/common.enum'

interface IProps {
  onSuccess: () => void
  onClose: () => void
  isOpen: boolean
  dataSource?: any
  articleType: ArticleTypeEnum
  categories: ArticleCategory[]
}
const WebinarDrawer = ({
  onSuccess,
  onClose,
  isOpen,
  dataSource,
  articleType,
  categories,
}: IProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (isOpen) {
      setLoading(false)
      if (dataSource?.id) {
        form.setFieldsValue(dataSource)
        form.setFieldValue('productIds', dataSource?.products?.map((item) => item.productId))
      }
    }
    return () => {
      isOpen && form.resetFields()
    }
  }, [isOpen])

  const handleSaveDraft = () => {
    form.setFieldValue('isActive', false)
    form.submit()
  }

  const onCancel = () => {
    form.resetFields()
    onClose()
  }

  const FooterDrawer = () => (
    <div className='flex justify-between text-right'>
      <div>
        <Button disabled={loading} onClick={onCancel} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
      <div className='flex gap-2'>
        <Button loading={loading} type='dashed' onClick={handleSaveDraft}>
          Save Draft
        </Button>
        <Button loading={loading} type='primary' onClick={() => form.submit()}>
          Save Publish
        </Button>
      </div>
    </div>
  )

  return (
    <Drawer
      title='Manage Content'
      width={1024}
      closable={false}
      maskClosable={false}
      onClose={onCancel}
      open={isOpen}
      bodyStyle={{ paddingBottom: 80 }}
      footer={<FooterDrawer />}
    >
      {!!isOpen && (
        <FormWebinar
          form={form}
          dataSource={dataSource}
          articleId={dataSource?.id}
          articleType={articleType}
          onSuccess={onSuccess}
          categories={categories}
        />
      )}
    </Drawer>
  )
}

export default WebinarDrawer
