import { useEffect, useState } from 'react'
import { Drawer, Button, FormInstance, Form } from 'antd'
import FormAgenda from './FormAgenda'

interface IProps {
  form: FormInstance
  onSuccess: (value) => void
  onClose: () => void
  isOpen: boolean
  dataSource?: any
  eventId: number | string | undefined
}
const DrawerAgenda = ({  onSuccess, onClose, isOpen, dataSource, eventId }: IProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (isOpen) {
      setLoading(false)
      form.setFieldValue('agenda', [])
    }

    return () => {
      isOpen && form.resetFields()
    }
  }, [isOpen])

  const onCancel = () => {
    form.resetFields()
    onClose()
  }

  const FooterDrawer = () => (
    <div className='flex justify-between text-right'>
      <div>
        <Button disabled={loading} onClick={onCancel} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
      <div className='flex gap-2'>
        <Button loading={loading} type='primary' onClick={() => form.submit()}>
          Save
        </Button>
      </div>
    </div>
  )

  return (
    <Drawer
      title={null}
      width={1024}
      closable={false}
      maskClosable={false}
      onClose={onCancel}
      open={isOpen}
      bodyStyle={{ paddingBottom: 80 }}
      footer={<FooterDrawer />}
    >
      {!!isOpen && (
        <FormAgenda
          form={form}
          eventId={eventId}
          dataSource={dataSource}
          onSuccess={onSuccess}
        />
      )}
    </Drawer>
  )
}

export default DrawerAgenda
