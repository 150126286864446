import { useEffect, useState } from 'react'
import {
  Col,
  Row,
  Spin,
  Form,
  Input,
  Modal,
  Switch,
  message,
  PageHeader,
  FormInstance,
  Tag,
  Button,
  Table,
} from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { createUser, updateUser, getUser } from 'services/user.service'

import dayjs from 'dayjs'
import { EventCalendar } from '@stores/types/event.types'

const { confirm } = Modal
const { Column } = Table
interface IProps {
  form: FormInstance
  userId?: number
  onSuccess?: (item?: any) => void
  dataSource?: any
}

const FormUser = ({ form, userId, onSuccess, dataSource = {} }: IProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [events, setEvents] = useState<EventCalendar[]>([])

  useEffect(() => {
    findUser()
  }, [])

  const onFinish = (values: any) => {
    console.log(values)
    confirm({
      title: 'Do you want to save it?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        const itemValue = {
          ...values,
        }
        console.log('itemValue', itemValue)
        if (userId) {
          updateUser(userId, itemValue)
            .then((response) => {
              if ([200].includes(response.status.code)) {
                message.success('Successfully saved.')
                if (typeof onSuccess === 'function') {
                  onSuccess(response.data)
                }
              }
            })
            .catch((error) => {
              setLoading(false)
              console.error(error)
            })
        }
      },
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false)
    console.log('Failed:', errorInfo)
  }

  const onApprove = () => {
    onFinish({ status: 'ACTIVE' })
  }

  const findUser = async () => {
    setLoading(true)
    const payload: any = await getUser(userId, {})
    if (payload?.status?.code === 200) {
      setEvents(payload.data?.events)
    }
    setLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        name='user-form'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          isActive: true,
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <Spin
          tip='Loading...'
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={16}>
            <Form.Item name='id'>
              <Input hidden />
            </Form.Item>

            <Col span={24}>
              <div className='leading-tight min-h-screen bg-grey-lighter'>
                <div className='mx-auto bg-white overflow-hidden'>
                  <div
                    className='bg-cover h-40'
                    style={{
                      backgroundImage:
                        'url("https://images.unsplash.com/photo-1522093537031-3ee69e6b1746?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=a634781c01d2dd529412c2d1e2224ec0&auto=format&fit=crop&w=2098&q=80")',
                    }}
                  ></div>
                  <div className='border-b px-4 pb-4'>
                    <div className='text-center sm:text-left sm:flex mb-4'>
                      {dataSource?.image ? (
                        <img
                          className='h-32 w-32 rounded-full border-4 border-white -mt-16 mr-4'
                          src={dataSource?.image}
                          alt=''
                        />
                      ) : (
                        <div className='h-32 w-32 rounded-full border-4 border-white -mt-16 mr-4 bg-secondary flex items-center justify-center text-primary'>
                          <h1 className='font-bold text-5xl uppercase'>
                            {dataSource?.name?.en?.charAt(0).toUpperCase()}
                          </h1>
                        </div>
                      )}

                      <div className='py-2 w-4/5'>
                        <h3 className='font-bold text-2xl mb-3 capitalize'>
                          {dataSource?.name?.en}{' '}
                          <small className='text-gray-500'>({dataSource?.name?.th})</small>
                        </h3>
                        <div className='text-xs'>
                          <Row gutter={16}>
                            <Col span={12}>
                              <div className='flex gap-2 mb-3'>
                                <div className='font-bold'>Email:</div>
                                <div className='text-gray-500'>{dataSource?.email}</div>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div className='flex gap-2 mb-3'>
                                <div className='font-bold'>Phone:</div>
                                <div className='text-gray-500'>{dataSource?.phone}</div>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div className='flex gap-2 mb-3'>
                                <div className='font-bold'>License:</div>
                                <div className='text-gray-500'>{dataSource?.license}</div>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div className='flex gap-2 mb-3'>
                                <div className='font-bold'>Branch:</div>
                                <div className='text-gray-500'>{dataSource?.branch}</div>
                              </div>
                            </Col>
                            <Col span={24}>
                              <div className='flex gap-2 mb-3'>
                                <div className='font-bold'>Experience:</div>
                                <div className='text-gray-500'>{dataSource?.experience}</div>
                              </div>
                            </Col>

                            <Col span={24}>
                              <div className='flex gap-2 mb-3'>
                                <div className='font-bold'>Clinic/Hospital:</div>
                                <div className='text-gray-500'>{dataSource?.workAt}</div>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div className='flex gap-2 mb-3'>
                                {dataSource?.status === 'ACTIVE' ? (
                                  <Tag color='success' className='m-0'>
                                    approved
                                  </Tag>
                                ) : (
                                  <Tag color='error' className='m-0'>
                                    wait to approve
                                  </Tag>
                                )}

                                {dataSource?.isVerify ? (
                                  <Tag color='processing' className='m-0'>
                                    email verified
                                  </Tag>
                                ) : (
                                  <Tag color='default' className='m-0'>
                                    unverified email
                                  </Tag>
                                )}
                              </div>
                            </Col>
                            <Col span={12}>
                              <div className='flex gap-2 mb-3'>
                                <div className='font-semibold'>Register Date:</div>
                                <div className='text-gray-500'>
                                  {dayjs(dataSource?.createdAt).format('DD-MM-YYYY h:mm:ss A')}
                                </div>
                              </div>
                            </Col>
                            <Col offset={12} span={12}>
                              <div className='flex gap-2 mb-3'>
                                <div className='font-semibold'>Last Updated:</div>
                                <div className='text-gray-500'>
                                  {dayjs(dataSource?.updatedAt).format('DD-MM-YYYY h:mm:ss A')}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className='-mx-4 -mb-2 flex justify-between'>
                      <div className='flex items-center'>
                        <Form.Item name='isActive' valuePropName='checked' className='!mb-0'>
                          <Switch defaultChecked />
                        </Form.Item>
                        <div className='ml-3 text-xs text-gray-400'>
                          This setting determines which data will be listed on status
                          (Active/InActive)
                        </div>
                      </div>
                      <div className=''>
                        {dataSource?.status === 'INACTIVE' && (
                          <Button
                            type='dashed'
                            size='small'
                            shape='round'
                            className='hover:text-white hover:bg-primary'
                            onClick={onApprove}
                          >
                            Approve User
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <PageHeader title='Events' subTitle='All subscribe event ' className='p-0' />

                    <Table
                      loading={loading}
                      pagination={false}
                      size='small'
                      rowKey={(record) => `faq-event-${record?.id + ''}`}
                      dataSource={events}
                      className='mt-2 mb-2'
                    >
                      <Column
                        title='Event Name'
                        className='break-word'
                        ellipsis
                        render={(record) => (
                          <div className='flex gap-2 text-md font-normal'>{record?.name?.en}</div>
                        )}
                      />
                      <Column
                        title='Event Name'
                        className='break-word'
                        ellipsis
                        render={(record) => (
                          <div className='flex gap-2 text-md font-normal'>
                            Date: {dayjs(record?.startDate).format('DD MMMM YYYY')}, Time:{' '}
                            {record?.startTime} - {record?.endTime}
                          </div>
                        )}
                      />
                    </Table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  )
}

export default FormUser
