import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Pagination,
  Row,
  Table,
  Modal,
  Input,
  message,
  Space,
  Tooltip,
  Select,
  Tag,
  MenuProps,
} from 'antd'
import { ExclamationCircleOutlined, FormOutlined, DeleteOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { useGlobalStore } from 'stores/global'
import { EventCalendar, EventTag } from 'stores/types/event.types'
import { deleteEvent, findEventTags, getEventUsers } from 'services/event.service'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { User } from 'stores/types/user.types'
import { EventSectionTypeConstant, EventStatusTypeConstant } from 'constants/event'
import { updateUserEvent } from 'services/user.service'
import { EventSectionTypeEnum, EventStatusTypeEnum } from 'enums/event.enum'

// import EventDrawer from './components/Drawer'
// import EventDrawerTag from './components/DrawerTag'

const { info } = Modal
const { Column } = Table
const { confirm } = Modal
const { Option } = Select
const { Search } = Input

export interface ISearchState {
  searchText: string | undefined
  status: string | undefined
  sectionType: string | undefined
}

const EventUserPage = () => {
  const navigate = useNavigate()
  const { eventId } = useParams()
  const { setPageHeader } = useGlobalStore()
  const [users, setUsers] = useState<User[]>([])
  const [eventCalendars, setEventCalendars] = useState<EventCalendar>()
  const [eventTags, setEventTags] = useState<EventTag[]>([])
  const [sorter] = useState({
    orderby: 'createdAt',
    sort: 'desc',
  })
  const [search, setSearch] = useState<ISearchState>({
    searchText: undefined,
    status: undefined,
    sectionType: undefined,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [stats, setStats] = useState<any>({
    register: 0,
    lecture: 0,
    workshop: 0,
    joined: 0,
  })

  let isDitMount = true

  useEffect(() => {
    if (isDitMount) {
      findAllTags()
    }
  }, [])

  useEffect(() => {
    if (eventCalendars?.id) {
      let tags: any = []
      if (eventTags?.length) {
        tags = eventCalendars?.tags?.map((item) => {
          const tagItem: any = eventTags.find((f) => f.id === item)
          return (
            <Tag key={`tag-event-${item}-${tagItem.id}`} color={tagItem?.options?.color}>
              {tagItem?.name?.en}
            </Tag>
          )
        })
      }

      setPageHeader({
        title: eventCalendars?.name?.en,
        subTitle: `Date: ${dayjs(eventCalendars?.startDate).format('DD MMMM YYYY')}, Time: ${eventCalendars?.startTime
          } - ${eventCalendars?.endTime}`,
        tags: tags,
        backIcon: <ArrowLeftOutlined />,
        onBack: '/medical-education/event',
        extra: [
          <Button
            key={1}
            type='dashed'
            className='bg-white hover:bg-primary hover:text-white'
            onClick={() => {
              navigate('/medical-education/event')
            }}
          >
            Back
          </Button>,
        ],
      })
    }
  }, [eventCalendars?.id])

  const findAll = async (param = {}) => {
    setLoading(true)
    const query = {
      // ...pagination,
      ...sorter,
      ...param,
    }
    const payload: any = await getEventUsers(eventId, query)
    if (payload?.status?.code === 200) {
      setEventCalendars(payload.data)
      setUsers(payload.data?.users)

      if (payload?.data?.users.length) {
        const eventUsers = payload?.data?.users
        setStats({
          register: `${eventUsers?.filter(f => f.status !== EventStatusTypeEnum.REJECTED)?.length}${payload?.data?.options?.quota ? `/${payload?.data?.options?.quota}` : ''}`,
          lecture: eventUsers.filter(f => f.UserEvent.sectionType === EventSectionTypeEnum.LECTURE)?.length || 0,
          workshop: eventUsers.filter(f => f.UserEvent.sectionType === EventSectionTypeEnum.WORKSHOP)?.length || 0,
          joined: eventUsers.filter(f => f.UserEvent.status === EventStatusTypeEnum.JOINED)?.length || 0,
        })
      }
    }
    setLoading(false)
  }

  const findAllTags = async () => {
    isDitMount = false
    setLoading(true)
    const payload: any = await findEventTags()
    if (payload?.status?.code === 200) {
      setEventTags(payload.data)
      findAll()
    }
    setLoading(false)
  }

  const updateStatus = async (item, status) => {
    let statusText: string = ''
    if (status === EventStatusTypeEnum.APPROVED) {
      statusText = 'Approve'
    }
    if (status === EventStatusTypeEnum.REJECTED) {
      statusText = 'Reject'
    }

    console.log(item)
    confirm({
      title: `Do you want to ${statusText} this user`,
      icon: <ExclamationCircleOutlined />,
      content: 'Please check infomation before Submiting!',
      onOk() {
        setLoading(true)
        updateUserEvent(item?.UserEvent?.id, { status }).then(() => {
          message.success('Update successfully')
          findAll()
        }).catch((err) => {
          console.log(err?.error?.code)
          setLoading(false)
          if (err?.error?.code == 409) {
            return message.error(err?.error?.detail)
          }
          message.error('Something went wrong!!')
        })
      }
    })
  }

  const colorStatus = (item) => {
    switch (item?.UserEvent?.status) {
      case EventStatusTypeEnum.WAITING_APPROVAL: return 'warning'
      case EventStatusTypeEnum.APPROVED: return 'processing'
      case EventStatusTypeEnum.JOINED: return 'success'
      case EventStatusTypeEnum.REJECTED: return 'error'
    }
    return 'processing'
  }

  const onSearch = (value) => {
    const searching = {
      ...search,
      searchText: value,
    }
    setSearch(searching)
    findAll({
      search: searching,
    })
  }

  const onSearchStatus = (value) => {
    const searching = {
      ...search,
      status: value,
    }
    setSearch(searching)
    findAll({
      search: searching,
    })
  }

  const onSearchSection = (value) => {
    const searching = {
      ...search,
      sectionType: value,
    }
    setSearch(searching)
    findAll({
      search: searching,
    })
  }

  const clearSearch = () => {
    const searching = {
      ...search,
      searchText: undefined,
      status: undefined,
      sectionType: undefined,
    }
    setSearch(searching)
    findAll({
      search: searching,
    })
  }
  return (
    <>
      <Row gutter={16}>
        <Col span={14} className='flex items-center'>
          <Space className='flex' align='end' >
            <Search
              allowClear
              size='large'
              placeholder='Search'
              className='w-[200px]'
              onSearch={onSearch}
              onChange={(event) =>
                setSearch({
                  ...search,
                  searchText: event.target.value,
                })
              }
              value={search.searchText}
              disabled={loading}
            />

            <Select
              size='large'
              placeholder='Search status'
              className='rounded-md'
              disabled={loading}
              allowClear
              onChange={onSearchStatus}
              value={search.status}
              style={{ width: 150 }}
            >
              <Option value={EventStatusTypeEnum.WAITING_APPROVAL}>
                {EventStatusTypeConstant.WAITING_APPROVAL}
              </Option>
              <Option value={EventStatusTypeEnum.APPROVED}>
                {EventStatusTypeConstant.APPROVED}
              </Option>
              <Option value={EventStatusTypeEnum.JOINED}>
                {EventStatusTypeConstant.JOINED}
              </Option>
              <Option value={EventStatusTypeEnum.REJECTED}>
                {EventStatusTypeConstant.REJECTED}
              </Option>
            </Select>

            <Select
              size='large'
              placeholder='Search section'
              className='rounded-md w-[270px]'
              disabled={loading}
              allowClear
              onChange={onSearchSection}
              value={search.sectionType}
            >
              <Option value={EventSectionTypeEnum.LECTURE}>
                {EventSectionTypeConstant.LECTURE}
              </Option>
              <Option value={EventSectionTypeEnum.WORKSHOP}>
                {EventSectionTypeConstant.WORKSHOP}
              </Option>
            </Select>

            <Button
              type='link'
              onClick={clearSearch}
              disabled={!search.searchText && !search.status && !search.sectionType}
            >
              clear
            </Button>
          </Space>
        </Col>
        <Col span={10}>
          <section className="py-4 w-full flex justify-end">
            <div className="w-full max-w-[400px]">
              <div className="flex flex-col flex-1 gap-10 lg:gap-0 lg:flex-row lg:justify-between">
                <div className="w-full lg:w-1/4 border-b pb-4 lg:border-b-0 lg:pb-0 lg:border-r border-gray-100">
                  <div className="font-manrope font-bold text-2xl text-gray-900 mb-2 text-center ">
                    {stats.register}
                  </div>
                  <span className="text-md text-gray-500 text-center block ">Registeration</span>
                </div>
                <div className="w-full lg:w-1/4 border-b pb-10 lg:border-b-0 lg:pb-0 lg:border-r border-gray-100">
                  <div className="font-manrope font-bold text-2xl text-gray-900 mb-2 text-center ">
                    {stats.lecture}
                  </div>
                  <span className="text-md text-gray-500 text-center block ">Lecture</span>
                </div>
                <div className="w-full lg:w-1/4 border-b pb-10 lg:border-b-0 lg:pb-0 lg:border-r border-gray-100">
                  <div className="font-manrope font-bold text-2xl text-gray-900 mb-2 text-center ">
                    {stats.workshop}
                  </div>
                  <span className="text-md text-gray-500 text-center block ">Workshop</span>
                </div>
                <div className="w-full lg:w-1/4  ">
                  <div className="font-manrope font-bold text-2xl text-gray-900 mb-2 text-center ">
                    {stats.joined}
                  </div>
                  <span className="text-md text-gray-500 text-center block ">Total Joined</span>
                </div>
              </div>
            </div>
          </section>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            rowKey={(record) => `event-${record?.id + ''}`}
            dataSource={users}
            className='mt-2 mb-2'
          >
            <Column
              title='Name'
              className='break-word'
              ellipsis
              width={200}
              render={(record) => (
                <div className='text-sm'>
                  <div className='font-bold'>{record?.name?.en}</div>
                  <div className='text-xs text-gray-500'>{record?.email}</div>
                </div>
              )}
            />
            <Column
              title='Information'
              className='break-word'
              ellipsis
              render={(record) => (
                <div className='text-xs '>
                  <div >Phone: {record?.phone}</div>
                  <div >License: {record?.license}</div>
                  <div >Rep: {record?.representative || 'Empty'}</div>
                </div>
              )}
            />

            <Column
              title='Section'
              className='break-word'
              align='center'
              render={(record) => (
                <div className='text-xs text-center'>
                  {EventSectionTypeConstant[record?.UserEvent?.sectionType]}
                </div>
              )}
            />

            <Column
              title='Register'
              className='break-word'
              align='center'
              width={110}
              render={(record) => (
                <div className='text-center text-xs'>{dayjs(record?.UserEvent?.createdAt).format('DD MMM YYYY HH:mm')}</div>
              )}
            />

            <Column
              title='Approved'
              className='break-word'
              align='center'
              width={110}
              render={(record) => (
                <div className='text-center text-xs'>
                  {record?.UserEvent?.approved ? dayjs(record?.UserEvent?.approved).format('DD MMM YYYY HH:mm') : ''}</div>
              )}
            />

            <Column
              title='Joined'
              className='break-word'
              align='center'
              width={110}
              render={(record) => (
                <div className='text-center text-xs'>
                  {record?.UserEvent?.joined ? dayjs(record?.UserEvent?.joined).format('DD MMM YYYY HH:mm') : ''}
                </div>
              )}
            />

            <Column
              title=''
              className='break-word'
              align='center'
              width={110}
              render={(record) => record.UserEvent.status === EventStatusTypeEnum.WAITING_APPROVAL ? (
                <div className='flex flex-col gap-2'>
                  <Button type='primary' block size='small' onClick={() => updateStatus(record, EventStatusTypeEnum.APPROVED)}>
                    Approve
                  </Button>
                  <Button type='dashed' block size='small' onClick={() => updateStatus(record, EventStatusTypeEnum.REJECTED)}>
                    Reject
                  </Button>
                </div>
              ) : (
                <div className='text-xs text-center'>
                  <Tag color={colorStatus(record)}>{EventStatusTypeConstant[record?.UserEvent?.status]}</Tag>
                </div>
              )}
            />
          </Table>
        </Col>
      </Row>
    </>
  )
}

export default EventUserPage
