import { EventSectionTypeEnum, EventStatusTypeEnum } from 'enums/event.enum'

export const EventSectionTypeConstant = {
  [EventSectionTypeEnum.LECTURE]: 'Section Lecture',
  [EventSectionTypeEnum.WORKSHOP]: 'Section Lecture + On hand Workshop',
}

export const EventStatusTypeConstant = {
  [EventStatusTypeEnum.WAITING_APPROVAL]: 'Awaiting Approval',
  [EventStatusTypeEnum.APPROVED]: 'Approved',
  [EventStatusTypeEnum.JOINED]: 'Joined',
  [EventStatusTypeEnum.REJECTED]: 'Rejected',
}