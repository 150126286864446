import { findAllProducts } from 'services/product.service'
import { Form, Select } from 'antd'
import { useEffect, useState } from 'react'

const { Option } = Select

const FormProductTag = () => {
  const [products, setProducts] = useState<any>([])

  useEffect(() => {
    fetchProduct()
  }, [])


  const fetchProduct = async () => {
    const query = {
      search: {
        fetch: 'tags'
      }
    }
    const payload = await findAllProducts(query)
    if (payload?.status?.code === 200) {
      setProducts(payload?.data)
    }
  }
  const OptionLabel = (item) => {
    return (
      <div className='flex flex-row gap-1 items-center'>
        <div className='relative -top-[1px]' style={{ width: '14px', height: '15px', backgroundColor: item.color }}></div>
        <div>{item.name}</div>
      </div>
    )
  }

  return (
    <Form.Item
      label='Products'
      name='productIds'
      rules={[{ required: true, message: 'please select products.' }]}
    >
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Select one of products"
        optionLabelProp="label"
        size='large'
      >
        {
          products?.length && products?.map((item, index) => {
            return (
              <Option key={`product-${index}`} value={item.productId} label={OptionLabel(item)}>
                {OptionLabel(item)}
              </Option>
            )
          })
        }

      </Select>
    </Form.Item>
  )
}

export default FormProductTag